<template>
  <div class="home">
    <div class="banner"><img src="../assets/images/banner1.jpg" alt="" /></div>
    <div class="current-position">
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span class="icon-home"></span>首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>安全行车</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="news">
      <el-timeline
        v-infinite-scroll="load"
        infinite-scroll-disabled="disabled"
        infinite-scroll-distance="3000"
      >
        <el-timeline-item
          v-for="item in newsList.data"
          :timestamp="item.create_time"
          :key="item.content_id"
          placement="top"
        >
          <el-card>
            <h4>
              <a
                :href="
                  '#/news/detail?id=' +
                    item.content_id +
                    '&navbar_title=安全行车'
                "
              >
                {{ item.title }}
              </a>
            </h4>
            <p>{{ item.summary }}</p>
          </el-card>
        </el-timeline-item>
        <el-divider v-if="loading">加载中...</el-divider>
        <el-divider v-if="nodata">暂无数据</el-divider>
        <el-divider v-if="noMore">没有更多了</el-divider>
      </el-timeline>
    </div>
  </div>
</template>

<script>
import { articleList } from '../api/article'

export default {
  name: 'Home',
  data() {
    return {
      loading: false,
      loadingData: [],
      newsList: {
        total: 2,
        data: []
      },
      category_id: 12
    }
  },
  computed: {
    noMore() {
      return this.newsList.data.length > this.newsList.total
    },
    nodata() {
      return this.newsList.data.length < this.newsList.total
    },
    disabled() {
      return this.loading || this.noMore || this.nodata
    }
  },
  created() {
    this.category_id = this.$route.query.category_id
    this.getCarData()
  },
  methods: {
    //获取汽车空间数据
    getCarData() {
      articleList({ category_id: this.category_id }).then(res => {
        if (res.code === 0) {
          this.newsList.data = res.result.slice(0, 2)
          this.loadingData = res.result.slice(2)
        }
      })
    },
    getData() {
      return this.loadingData
    },
    load() {
      this.loading = true
      setTimeout(() => {
        let data = this.getData()
        data.forEach(item => {
          this.newsList.data.push(item)
        })
        this.loading = false
      }, 2000)
    }
  },
  components: {}
}
</script>
